<template>
  <b-modal
      id="notify-line-modal"
      hide-footer
      title="ลงทะเบียนระบบออโต้ ดึงแจ้งเตือน Line"
  >
    <b-overlay :show="isFetching">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider
              v-slot="{ errors }"
              name="phone"
              rules="required|length:10"
          >
            <b-form-group label="เบอร์โทรศัพท์">
              <b-form-input
                  v-model="form.phone"
                  :aria-invalid="false"
                  :state="errors[0] ? false : null"
                  name="phone"
                  placeholder="เบอร์โทรศัพท์ที่ใช้สมัครไลน์"
                  type="text"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <div class="text-right">
            <b-button :disabled="isFetching" type="submit" variant="success">
              ลงทะเบียน
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';

export default Vue.extend({
  name: 'AutoBankingLineNotifyModal',
  props: {
    agentBankAccountId: {
      type: String,
      required: true
    },
    linkWallet: {
      type: Object,
      default: () => ({
        phone: '',
      })
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isFetching: false,
      form: {
        phone: '',
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  created() {
    this.form = {
      phone: this.linkWallet?.config?.phone || '',
    }
  },
  methods: {
    ...mapActions(['registerLinkWallet', 'fetchBankAccount']),
    async onSubmit() {
      this.isFetching = true
      const response = await this.registerLinkWallet({ id: this.agentBankAccountId, data: {
        config: this.form,
        type: this.type,
      }})
      if(response) {
        this.$bvModal.hide('notify-line-modal')
        await this.fetchBankAccount(this.agentBankAccountId)
      }
      this.isFetching = false
    },
    onCopy() {
      this.$bvToast.toast('คัดลอก URL เรียบร้อย', {
        variant: 'success',
        title: 'Success'
      })
    },
  }
})
</script>

<style scoped>

</style>